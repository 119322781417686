const academicsData = [
    {
      eventId:19,
      title: 'Dr Know it all - Mediquiz',
      eventType:'[TEAM OF 4]',
      description: 'In the realm of knowledge, you’ll stand, ready for questions, hand in hand. A quiz, a test, to prove your might. With answers swift, you’ll shine so bright.',
     
      price_in: 'Rs500',
      price_us:'$6',
      image: require('../assets/acad1.png'), 
    },
    {
      eventId:20,
      title: 'Murder Mystery - A Crime Scene Investigation',
      description: 'As the time ticks out, the killer hides amongst you. Keep your eyes peeled out for clues before the next body found is you. <br></br>(ONLY 1 ENTRY PER COLLEGE)',
      eventType:'[TEAM OF 3]',
      price_in: 'Rs750',
      price_us:'$10',
      totalSeats:10,
      image: require('../assets/acad2.png'), 
    },
    {
      eventId:21,
        title: 'Effugium - An Escape Room',
        description: 'Find your way out of a sticky situation by braving through bewildering clues. Prepare to be tested to the breadth of your knowledge in this exhilarating experience.<br></br>(ONLY 1 ENTRY PER COLLEGE)',
        eventType:'[TEAM OF 4]',
        price_in: 'Rs1000',
      price_us:'$12.5',
      totalSeats:10,
        image: require('../assets/acad3.png'), 
      },
      // {
      //   eventId:22,
      //   title: 'Abstract Alchemy - Transforming Research into Insights',
      //   description: 'Bring forth your ideas in the world of research and add to the ever flowing pool of wisdom.',
      //   eventType:'[TEAM OF 2]',
      //   price_in: 'Rs1000',
      // price_us:'$12',
      //   image: require('../assets/acad4.png'), 
      // },
      {
        eventId:23,
        title: 'Contra Puntum - Point Counter Point',
        description: 'When was the last time you had a satisfying argument that made you question your own ideas and counter yourself with the best of both worlds. Here we are with point counterpoint an opportunity to view a concept from both extremes and put forward conflicting opinions on current medical and social issues.',
       
        price_in: 'Rs250',
      price_us:'$3.5',
      totalSeats: 25,
        image: require('../assets/acad5.png'), 
      },
      // {
      //   eventId:24,
      //   title: 'Medical Musical Chairs',
      //   description: 'Websters defines Musical Chairs as a game in which players march to music around a row of chairs numbering one less than the players and scramble for seats when the music stops. We decided to add a little twist.',
     
      //   price_in: 'Rs100',
      // price_us:'$2',
      //   image: require('../assets/acad6.png'), 
      // },
      {eventId:25,
        title: 'Skin it up!',
        description: 'Are you an artistic medico? How about painting on a unique canvas to test your human anatomical knowledge!!',
        eventType:'[TEAM OF 2]',
        price_in: 'Rs300',
      price_us:'$5',
        image: require('../assets/acad7.png'), 
      },
      {
        eventId:26,
        title: 'PlanetFlicks - Lights, Camera, Change!',
        description: 'Are you ready to embark on a journey through the unexplored realms of medical significance in climate change? PlanetFlicks is a documentary competition that invites filmmakers from around the world to capture and unveil the untold stories that shape our diverse and dynamic planet.',
        eventType:'[MAX 8 PER TEAM]',
        price_in: 'Rs500',
        price_us:'$8',
        image: require('../assets/acad8.png'), 
      },
      
  ];
  export default academicsData;
  