const sportsData = [
    {
      eventId:14,
      title: 'Football (Boys)',
      description: 'Feel the adrenaline rush as the football teams face off in intense matches. Expect skill, teamwork, and unmatched passion on the field.',
      eventType:'[TEAM OF 7]',
      price_in: 'Rs2000',
      price_us:'$25',
      image: require('../assets/sports1.png'), 
    },
    {
      eventId:15,
      title: 'Basketball (Boys)',
      description: 'Basketball courts will echo with the sound of dribbles and swishing nets as the teams compete for glory. Fast-paced and action-packed !',
      eventType:'[TEAM OF 5]',
      price_in: 'Rs2000',
      price_us:'$25',
      image: require('../assets/sports2.png'), 
    },
    {
      eventId:31,
      title: 'Basketball (Girls)',
      description: 'Basketball courts will echo with the sound of dribbles and swishing nets as the teams compete for glory. Fast-paced and action-packed !',
      eventType:'[TEAM OF 5]',
      price_in: 'Rs2000',
      price_us:'$25',
      image: require('../assets/sports2.png'), 
    },
    {
      eventId:16,
      title: 'Volleyball (Boys)',
      eventType:'[TEAM OF 6]',
      description: 'The sands of the volleyball courts will witness incredible spikes and blocks as the teams aim for victory. An electrifying spectacle!',
      
      price_in: 'Rs2000',
      price_us:'$25',
      image: require('../assets/sports3.png'), 
    },
    {
      eventId:32,
      title: 'Volleyball (Girls)',
      eventType:'[TEAM OF 6]',
      description: 'The sands of the volleyball courts will witness incredible spikes and blocks as the teams aim for victory. An electrifying spectacle!',
      
      price_in: 'Rs2000',
      price_us:'$25',
      image: require('../assets/sports3.png'), 
    },
    {
      eventId:17,
      title: 'Kho-Kho (Girls)',
      eventType:'[TEAM OF 9]',
      description: 'Kho-Kho is not just a sport, its a display of agility and strategy. Girls will compete with lightening-fast moves and teamwork.',
      price_in: 'Rs2000',
      price_us:'$25',
      image: require('../assets/sports4.png'), 
    },
    {
      eventId:18,
      title: 'Cycle Rally',
      description: "For every cyclist who participates, we'll plant one sampling, contributing to a greener, more sustainable future. It's a powerful way to combine fitness, environmental responsibility and community engagement",
      price_in: 'Rs100',
      price_us:'$2',
      image: require('../assets/cycle.png'), 
    },
    
  ];
  export default sportsData;
  