const workshopsData = [
    {
      eventId:1,
      title: 'Orthoquest - Harmonizing Joint Care',
      description: 'Welcome to a specialized training program designed to enhance the skills and knowledge of the hidden orthopedic surgeon within you. Hone your skills with our interactive sessions involving tendon suturing, bone remodeling,splinting and casting and so much more.',
      price_in: 'Rs2000',
      price_us:'$25',
      totalSeats: 30,
      intlSeats: 3,
    ntnlSeats: 17,
      image: require('../assets/event-1.png'),
    },
    {
      eventId:2,
      title: 'NeuroNexus - Dive into the world of Neurology',
      description: 'Delve into the intricacies of the human mind and be guided through the secrets of interventional stroke management along with hands on simulation of mechanical thrombectomy.',
      price_in: 'Rs2000',

      price_us:'$25',
      totalSeats: 20,
      intlSeats:0,
      ntnlSeats:0,
      image: require('../assets/event-2.png'),
    },
    {
      eventId:3,
      title: 'Scalpel to Stitches - The Art of Surgery',
      description: 'Have you ever wondered what it felt like to do a laparoscopic surgery? Well here’s your chance. We present to you a simulation beyond reality and an opportunity to refine your surgical skills.',
      price_in: 'Rs2000',
      price_us:'$25',
      totalSeats: 30,
      intlSeats:1,
      ntnlSeats:8,
      image: require('../assets/event-3.png'),
    },
    {
      eventId:4,
      title: 'Beyond the Beat - Cardiology Unveiled',
      description: 'Join us for an immersive journey into the intricate world of cardiology. Discover the beating heart of modern healthcare as we delve deep into the latest advancements, diagnostic and therapeutic techniques like pace maker insertion and coronary artery stenting',
      price_in: 'Rs2000',
      price_us:'$25',
      totalSeats: 30,
      intlSeats:3,
      ntnlSeats:13,
      image: require('../assets/event-4.png'),
    },
    {
      eventId:5,
      title: 'Birth & Beyond - Gynecological and Obstetrics Symposium',
      description: 'Finally a workshop where participants will have the opportunity to learn from experienced gynecologists and obstetricians. They will be exposed to the latest advancements in gynecology and obstetrics, including hands-on simulations of normal vaginal delivery/breech delivery, diagnostic techniques, surgical procedures, interactive drills and treatment options for various gynecological conditions.',
      price_in:'Rs1500',
      price_us:'$20',
      totalSeats: 30,
      intlSeats:3,
      ntnlSeats:16,
      image: require('../assets/event-5.png'),
    },
    {
      eventId:6,
      title: 'Salus Mentis - Exploring Mental Health',
      description: 'Welcome to an immersive psychology workshop designed to delve deep into the fascinating realm of the human psyche. Our workshop is your gateway to understanding the complexities of the human mind, its intricate workings, and the profound impact it has on our thoughts, emotions, and behaviors.',
      price_in:'Rs200',
      price_us:'$4',
      totalSeats: 60,
      intlSeats:5,
      ntnlSeats:23,
      image: require('../assets/event-6.png'),
    },
    {
      eventId:7,
      title: 'Scientia Sanguinis - Mastering Hematology',
      description: 'Join us for an exciting and informative journey into the captivating world of hematology. Embark on a journey as we go through the steps in bone marrow transplantation and become potential stem cell donors. Learn about the pathological mutations in hematology and much more.',
      price_in: 'Rs1500',
      price_us:'$20',
      totalSeats: 30,
      intlSeats: 3,
      ntnlSeats:15,
      image: require('../assets/event-7.png'),
    },
    {
      eventId:8,
      title: 'Transfuse Life - Mastering the Art of Blood Transfusion',
      description: "Here's a workshop for all the enthusiastic blood donors, that typically covers a wide range of topics related to blood transfusion, including blood grouping and cross-matching in an immunohematology lab, donor phlebotomy, blood product preparation and storage, transfusion reactions and complications, donor selection and screening.",
      price_in: 'Rs800',
      price_us:'$10',
      totalSeats: 30,
      intlSeats: 3,
      ntnlSeats:13,
      image: require('../assets/event-8.png'),
    },
    {
      eventId:9,
      title: 'Safeguarding Lives - A Critical Care Workshop',
      description: 'Welcome to a specialized training that offers a combination of hands-on landmark based central line insertion with interactive discussions to enhance participants knowledge and skills in the field of anesthesia.',
      price_in:'Rs1500',
      price_us:'$20',
      totalSeats: 30,
      intlSeats:3,
      ntnlSeats:14,
      image: require('../assets/event-9.png'),
    },
  ];
  export default workshopsData;
  