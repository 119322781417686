const upcomingEvents = [
    {
      title: '',
      imageUrl: require('../assets/upcoming-events1.jpg'),
    },
    
    {
      title: '',
      imageUrl: require('../assets/upcoming-events2.jpg'),
    },
  ];
  
  export default upcomingEvents;
  