const culturalData = [
    // {
    //   title: 'Photography Competition',
    //   description: '',
    //   price: 'Rs200',
    //   image: '../assets/workshop.png', 
    // },
    // {
    //   title: 'Change Zaroori Hai - Reels Competition',
    //   description: 'Clean your surroundings and cleanse your mind! And bring the change you want to see. This year as we focus on the theme of health impacts of climate change, showcase your unique talents on social media to depict the theme. Exhibitions can include dance, rap, comedy, original songs/music videos, short film.',
    //   price: 'Rs200',
    //   image: require('../assets/cultural2.png'), 
    // },
    // {
    //   title: 'Beer Pong',
    //   description: 'Splish, splash, hope your forearm’s not trash’ Dive in and indulge yourself in the age old party game ; just life sized. Compete as you toss the ball into the opponents buckets until none remain.',
    //   price: 'Rs200',
    //   image: require('../assets/cultural3.png'), 
    // },
    {
      eventId:10,
      title: 'FIFA Fever Showdown',
      description: 'Get ready for a fusion of healing hearts and football artistry! FIFA Fever Showdown, invites you to discover a world where medical marvels take a center stage on the virtual pitch.',
      price_in: 'Rs200',
      price_us:'$3',
      image: require('../assets/cultural4.png'), 
    },
    {
      eventId:11,
      title: 'BGMI',
      description: 'Survive, Dominate, Win!!!! Are you ready?',
      eventType:'[TEAM OF 4]',
      price: 'Rs200',
      price_in: 'Rs200',
      price_us:'$3',
      image: require('../assets/cultural8.png'), 
    },
    {
      eventId:12,
      title: 'Pop quiz',
      description: 'It’s time to gather the brightest general knowledge buffs in the area because we are having a pop quiz! We have curated a collection of exciting cultural quiz questions spanning various topics, from iconic books to chart-topping hits across multiple decades. Now lets get on with it and get our hearts pumping with adrenaline for this exhilarating quiz!',
      price: 'Rs200',
      price_in: 'Rs200',
      price_us:'$3',
      image: require('../assets/cultural6.png'), 
    },
    {
      eventId:13,
      title: 'Treasure hunt',
      description: 'Its time to figure out....Why is that bird so rude?? In this game participants will be divided in teams. Each team will be given clues which lead them to a particular location where they will be required to complete tasks in order to receive the next clue. The team which finishes all the tasks in the minimum amount of time will be declared as winner.',
      eventType:'[TEAM OF 4]',
      price_in: 'Rs400',
      price_us:'$5',
      totalSeats: 10,
      image: require('../assets/cultural7.png') , 
    },
    {
      eventId:33,
      title: 'Introducing the future of music experience: Silent DJ',
      description: 'Immerse yourself in the unique experience of a Silent DJ on our campus. Let the beats resonate through wireless headphones creating a vibrant atmosphere, a platform for you to display your grooves while the world around stays serene.',
      totalSeats:150,
      price_in: 'Rs300',
      price_us:'$4',
      image: require('../assets/culturals8.jpeg') , 
    },
  
  ];
  export default culturalData;
  