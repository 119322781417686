import React from 'react';
import './becomeSponsor.css'
import sponsImage from '../assets/spons-img.svg';
const SponsorSection = () => {
  return (
    <div className="sponsor-section">
      <h2>Become a Sponsor</h2>
      <img src={sponsImage} alt="Become a Sponsor" />
      <p>
      
Christian Medical College, Ludhiana has been a pioneer in medical education for about 130 years. From four students back in 1894, to 100 students per batch the journey has been long and fruitful. Today, using advanced teaching methods, we aspire to provide and promote medical education to students all over the country and beyond through workshops and medical conventions.<br></br><br></br>

Medical Olympics 2024 was born with the idea to integrate both medical and co-curricular events under one banner to promote and nurture the brain and brawns of young budding doctors both in India and beyond.<br></br><br></br>

Medical Olympics will include a variety of medical conferences and workshops along with sports and cultural events spanning across three days which will be a common platform for medical students to interact with each other and make connections across the globe. This is a unique opportunity for you to participate and sponsor a medical event increasing your brand visibility. It also gives you a chance to involve yourself in the community and help in training of the future doctors of our country. Last but not the least multiple benefits for the sponsors are listed based on the level of sponsorship you wish to provide.<br></br><br></br>
      </p>
      <a href="https://forms.gle/8R4hfdBAJDbD6DEX9" class="apply-now-button">Apply Now!</a>

    </div>
  );
};

export default SponsorSection;
